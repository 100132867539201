import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Kipping Pullups`}</p>
    <p>{`then,`}</p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`12-KBS’s (53/35)`}</p>
    <p>{`12-Right Arm KB Front Rack Squats`}</p>
    <p>{`12-Left Arm KB Front Rack Squats`}</p>
    <p>{`12-Pullups`}</p>
    <p>{`24-Situps`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Grab 2 friends and register for the Granite Games Spring Throwdown
on March 14th!  February 16th is the last day to register and get your
free Granite Games t shirt.  The 16th is also the last day to get your
volunteer t shirt so if you can’t compete please consider helping.  Sign
up to compete or volunteer
at: `}<a parentName="em" {...{
            "href": "https://competitioncorner.net/events/3164"
          }}>{`https://competitioncorner.net/events/3164`}</a></em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      